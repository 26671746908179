<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		

		<a-card :bordered="false" class="header-solid px-5 mb-5 pb-1" :bodyStyle="{padding: 0, paddingTop: '16px', marginBottom: '90px !important'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Product Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditItem">
						<a-icon type="form" /> Edit
					</a-button>

					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditPrice">
						<a-icon type="form" /> Edit Price
					</a-button>

					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #F44335; font-size: 16px;" @click="showDeletionConfirmation">
						<a-icon type="delete" /> Delete
					</a-button>

					<a-button type="primary" :href="`/shop-management/products`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="">
			
				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Name</strong></label>
						<a-input 
							disabled
							v-model="product.name"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Type</strong></label>
						<a-input 
							disabled
							v-model="product.type"
							/>
					</a-col>
				</a-row>



				<a-row :gutter="24">

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Price</strong></label>
						<a-input 
							disabled
							v-model="product.price"
							/>
					</a-col>
	
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Description</strong></label>
						<a-input 
							disabled
							v-model="product.description"
							/>
					</a-col>

					
				</a-row>

				
			</div>
		</a-card>
		

		<!--FORM MODAL -->
		<a-modal v-model="product.showModal" :footer="null">
			<h4 class="text-center">{{ product.isCreate ? 'Add Product' : 'Edit Product'}}</h4>
			<a-form
				:model="product"
                :form="formItem"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Name"
					:colon="false">
						<a-input 
							name="name"
							v-decorator="['name', { rules: [{ required: true, message: 'Please enter the name of the product!' }] }]"
							placeholder="Name"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Type"
					placeholder="Select Type"
					:colon="false">
					<a-select placeholder="Select Type" 
						name="typeUuid"
						v-decorator="[ 'typeUuid',
						{ rules: [{ required: true, message: 'Please select route of the product!' }] },]">
						<a-select-option v-for="option in productTypes"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Description"
					:colon="false">
						<a-input 
							name="description"
							v-decorator="['description', { rules: [] }]"
							placeholder="Description"/>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#D15A1B" :loading="product.loading" html-type="submit" class="">
						{{ product.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>

		<!-- PRICE FORM MODAL -->
		<a-modal v-model="productPrice.showModal" :footer="null">
			<h4 class="text-center">{{ productPrice.isCreate ? 'Add Price' : 'Edit Price'}}</h4>
			<a-form
				:model="productPrice"
                :form="formPrice"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmitPrice">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Price"
					:colon="false">
						<a-input  
							name="price"
							type="number"
							v-decorator="[ 'price',
								{ rules: [{ required: true, message: 'Please enter the price of the product!' }] },]"
							placeholder="Price"/>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#D15A1B" :loading="productPrice.loading" html-type="submit" class="">
						{{ 'Change' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


		<!-- DELETE CONFIRMATION -->
		<a-modal v-model="deleteItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this product?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelproductDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button :loading="deleteItem.loading" id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>


    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
				heading: 'Shop',
				subheading: 'Product Details',
				icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
		
				fields: [ 'first_name', 'last_name', 'age' ],
				items: [
				{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
				{ age: 21, first_name: 'Larsen', last_name: 'Shaw' },
				{ age: 89, first_name: 'Geneva', last_name: 'Wilson' }
				],
				striped: false,
				bordered: false,
				outlined: false,
				small: false,
				hover: false,
				dark: false,
				fixed: false,
				footClone: false,

				pagination: {
					search: '',
					loading: false,
					perPage: 10,
					currentPage: 1,
					perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
					total: 10
				},
				
				message: '',

				records: [],
				productTypes: [],
				
				// formItem: null,
				// formPrice: null,
				rules,

				product: {
					uuid: null,
					name: null,
					type: null,
					typeUuid: null,
					price: null,
					priceObject: {},
					description: null,
					isCreate: false,
					showModal: false,
					loading: false,
				},

				productPrice: {
					uuid: null,
					price: null,
					isCreate: false,
					showModal: false,
					loading: false,
				},


				deleteItem: {
					uuid: null,
					showModal: false,
					loading: false,
				},

				userDetails: {
					user: null,
					company: null
				},

				notify: {
					text: null,
					type: null,
				},
			}
		},
		computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		beforeCreate() {
			this.formItem = this.$form.createForm(this, { name: 'newItemForm' });
			this.formPrice = this.$form.createForm(this, { name: 'newPriceForm' });
		},
		created() {
            this.getUserDetails();
			this.getProductDetails();
			this.getProductTypes();
		},
		methods: {


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


			async getProductDetails() {
                
                let url = `${this.$BACKEND_URL}/products/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
						this.product.uuid = response.data.uuid;
						this.product.name = response.data.name;
						this.product.price = response.data.price.price;
						this.product.priceObject = response.data.price;
						this.product.type = response.data.type.name;
						this.product.typeUuid = response.data.type.uuid;
						this.product.description = response.data.description;
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },


			async getProductTypes() {

                const currentPage = 1;
				const perPage = 1000;

				let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/product-types/${companyUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.productTypes = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "danger"
                    this.$notify(this.notify)

                });
            },


			async onEditItem() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.product.isCreate = false;
				this.product.showModal = true;
                this.product.uuid = this.$route.params.uuid;
                this.product.companyUuid = this.userDetails.company.uuid;
				this.product.loading = false;
				// eslint-disable-next-line no-console
				setTimeout(() => {
					this.formItem.setFieldsValue({
						name: this.product.name,
						typeUuid: this.product.typeUuid,
						description: this.product.description,
					});
				}, 10);

            },



			async handleSubmit() {

				this.formItem.validateFields((err, values) => {
					if ( !err ) {

						this.product.loading = true;

						// eslint-disable-next-line no-console
			
						let url = `${this.$BACKEND_URL}/products/${this.$route.params.uuid}`

						let payload = values;

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.formItem.resetFields()

								this.product.uuid = null;
								this.product.name = null;
								this.product.codeName = null;
								this.product.description = null;
								this.product.isCreate = true;
								this.product.loading = false;
								this.product.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getProductDetails()
			
						}).catch(error => {
						
							this.product.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});

					}
					
				})
				
			},


			async onEditPrice() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.productPrice.isCreate = false;
				this.productPrice.showModal = true;
                this.productPrice.uuid = this.$route.params.uuid;
                this.productPrice.companyUuid = this.userDetails.company.uuid;
				this.productPrice.loading = false;
				// eslint-disable-next-line no-console
				setTimeout(() => {
					this.formPrice.setFieldsValue({
						price: this.product.price,
					});
				}, 10);

            },


			async handleSubmitPrice() {

				this.formPrice.validateFields((err, values) => {
					if ( !err ) {

						this.productPrice.loading = true;

						// eslint-disable-next-line no-console
			
						let url = `${this.$BACKEND_URL}/products/price`

						let payload = {
							price: values.price,
							companyUuid: this.userDetails.company.uuid,
							productUuid: this.$route.params.uuid,
						};

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.formItem.resetFields()

								this.productPrice.uuid = null;
								this.productPrice.price = null;
								this.productPrice.isCreate = true;
								this.productPrice.loading = false;
								this.productPrice.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getProductDetails()
			
						}).catch(error => {
						
							this.productPrice.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});

					}
					
				})
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteItem.uuid = uuid;
				this.deleteItem.showModal = true;
			},

			cancelproductDeletion() {
				this.deleteItem.uuid = null;
				this.deleteItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteItem.loading = true;

                let url = `${this.$BACKEND_URL}/products/${this.$route.params.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteItem.uuid = null;
					this.deleteItem.showModal = false;
                
                    this.deleteItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.$router.push("/shop-management/products")

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.deleteItem.loading = false;
                });
			},


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>